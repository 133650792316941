// 校管理路由
import Main from "@/views/main/index";

const teacher = [
  {
    path: '/',
    name: 'teacher',
    meta: {
      title: '教师管理',
      auth: 0
    },
    component: Main,
    children: [
      {
        path: '/pushwork',
        name: 'pushwork',
        meta: {
          title: '发布作业',
          auth: 30
        },
        component: () => import('../views/modules/pushwork/index.vue')
      },
      {
        path: '/detail-list',
        name: 'detail-list',
        meta: {
          title: '某次扫描详情列表',
          auth: 30
        },
        component: () => import('../views/modules/common/homeWork/detail-list.vue')
      },
      {
        path: '/detail-single',
        name: 'detail-single',
        meta: {
          title: '某次扫描详情单个',
          auth: 30
        },
        component: () => import('../views/modules/common/homeWork/detail-single.vue')
      },
      {
        path: '/teacher-commonclass',
        name: 'teacher-commonclass',
        meta: {
          title: '班级管理',
          auth: 30
        },
        component: () => import('../views/modules/teacher/teacher-commonclass.vue')
      },
      {
        path: '/teacher-student',
        name: 'teacher-student-index',
        meta: {
          title: '学生管理首页',
          auth: 30
        },
        component: () => import('../views/modules/teacher/teacher-student.vue')
      },
      {
        path: '/teacher-homework',
        name: 'teacher-homeWork',
        meta: {
          title: '班级作业',
          auth: 30
        },
        component: () => import('../views/modules/teacher/teacher-homework.vue')
      },
      {
        path: 'batch-scan',
        name: 'batch-scan',
        meta: {
          title: '批量扫描',
          auth: 30
        },
        component: () => import('../views/modules/teacher/batch-scan.vue')
      },
    ]
  }
]

export default teacher
