import axios from 'axios'
import {Message} from 'view-design';
import user from "@/user";

let baseURL = '/api'

/*if (process.env.NODE_ENV === 'production') {
    baseURL = window._CONFIG['domianURL']
}*/

function errorLoading(message) {
  Message.error({message: message, type: 'error'})
}

const service = axios.create({
  baseURL: baseURL,
  timeout: 20000
})

service.interceptors.request.use(config => {
  const token = user.getToken()
  if (token) {
    config.headers['X-Access-Token'] = token // 让每个请求携带自定义 token 请根据实际情况自行修改
  }

  if (config.method === 'get') {
    config.params = {
      _t: Date.parse(new Date()) / 1000,
      ...config.params
    }
  }
  return config
}, error => {
  return Promise.reject(error)
})

service.interceptors.response.use(response => {
  const responseCode = response.status
  if (responseCode === 200) {
    let data = response.data
    if (data.code == 401) {
      errorLoading('登录已过期，请重新登录')
      user.clear()
      window.location.replace('/login')
    }
  }
  return Promise.resolve(response.data)
}, error => {
  if (error == "Error: Network Error") {
    // loading 错误提示
    errorLoading('无网络信号')
  }
  if (error == 'Error: timeout of 10000ms exceeded' || error == "Error: Request failed with status code 500") {
    // loading 错误提示
    errorLoading('网络链接超时')
  }
  return Promise.reject(error.response ? error.response.data : error)
})

export function postAction(url, data) {
  return service({
    url: url,
    method: 'post',
    data: data
  })
}

export function UploadAction(url, parameter, config) {
  return service({
    url: url,
    method: 'post',
    data: parameter,
    config
  })
}

// post method= {post | put}
export function httpAction(url, parameter, method) {
  return service({
    url: url,
    method: method,
    data: parameter
  })
}

// put
export function putAction(url, parameter) {
  return service({
    url: url,
    method: 'put',
    data: parameter
  })
}

// get
export function getAction(url, parameter) {
  return service({
    url: url,
    method: 'get',
    params: parameter
  })
}

// deleteAction
export function deleteAction(url, parameter) {
  return service({
    url: url,
    method: 'delete',
    params: parameter
  })
}
