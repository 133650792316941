import Vue from 'vue'
import App from './App.vue'
import {router} from './router'
import {api} from './network/api'
import store from './store'
import ViewUI from 'view-design';
import './my-theme/index.less';
import './components'
import user from './user'
import {Base64} from 'js-base64'
import axios from 'axios'
import utils from "@/utils/utils";

Vue.use(ViewUI);
Vue.config.productionTip = false

Vue.prototype.$http = api
Vue.prototype.$user = user
Vue.prototype.$utils = utils;

// 公共路由跳转
Vue.prototype.$jump = (path, query, isReplace = false) => {
    let queryEncode = undefined;
    if (query) {
        queryEncode = {
            spn: Base64.encode(JSON.stringify(query))
        }
    }
    if (isReplace) {
        router.replace({
            path: path,
            query: queryEncode
        })
    } else {
        router.push({
            path: path,
            query: queryEncode
        })
    }
}

// 公共获取路由参数
Vue.prototype.$routeQuery = () => {
    let queryDecode = {}
    let query = router.currentRoute.query
    if (query) {
        try {
            queryDecode = JSON.parse(Base64.decode(query.spn)) || {}
        } catch (e) {
            //console.log('error', e)
        }
    }
    return queryDecode;
}

Vue.prototype.$downloadExcel = (url, name, format) => {
    const token = user.getToken();
    axios({
        url: url,
        method: 'get',
        headers: {"X-Access-Token": token},
        responseType: 'blob',
    }).then(function (res) {
        let blob = res.data;
        // FileReader主要用于将文件内容读入内存
        let reader = new FileReader();
        reader.readAsDataURL(blob);
        // onload当读取操作成功完成时调用
        reader.onload = function (e) {
            let a = document.createElement('a');
            // 获取文件名fileName
            a.download = name + format;
            a.href = e.target.result;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        }
    })
}

Vue.prototype.$downloadDoc = (url, pushType, classIds, students) => {
    let token = user.getToken();
    let front = 'api';
    let suffix = '.pdf';
    axios({
        url: front + url,
        method: 'post',
        headers: {'X-Access-Token': token},
        responseType: 'blob',
        data: {
            pushType: pushType,
            classIds: classIds,
            students: students
        }
    }).then(function (res) {
        let blob = res.data;
        // FileReader主要用于将文件内容读入内存
        let reader = new FileReader();
        reader.readAsDataURL(blob);
        // onload当读取操作成功完成时调用
        reader.onload = function (e) {
            let a = document.createElement('a');
            // 获取文件名fileName
            a.download = '作业' +utils.getTimeStr(new Date()) +suffix;
            a.href = e.target.result;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        }
    })
}

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
