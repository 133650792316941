<template>
  <div>
    <div class="demo-upload-list" v-for="(item,index) in uploadList" :key="index"
         :style="{width: width+'px', height:width+'px',lineHeight: width+'px'}">
      <template v-if="item.status === 'finished'">
        <img :src="item.url">
        <div class="demo-upload-list-cover">
          <Icon type="ios-eye-outline" @click.native="handleView(item.name)"></Icon>
          <Icon type="ios-trash-outline" @click.native="handleRemove(item)"></Icon>
        </div>
      </template>
      <template v-else>
        <Progress v-if="item.showProgress" :percent="item.percentage" hide-info></Progress>
      </template>
    </div>
    <Upload ref="upload" :show-upload-list="false"
            v-if="uploadList.length < imgNumber"
            :before-upload="beforeUpload"
            :on-success="handleSuccess"
            :format="['jpg','jpeg','png']"
            :max-size="2048"
            :on-format-error="handleFormatError"
            :on-exceeded-size="handleMaxSize"
            type="drag"
            :action="uploadAction"
            :style="{display: 'inline-block',width:width + 'px'}">
      <div :style="{width: (width - 2)+'px', height:(width - 2)+'px',lineHeight: (width - 2)+'px'}">
        <Icon type="ios-camera" size="20"></Icon>
      </div>
    </Upload>
  </div>
</template>

<script>
export default {
  name: "",
  components: {},
  data() {
    return {
      uploadAction: 'https://api.exhui.net/api/qiniu/file/upload',
      defaultList: [],
      uploadList: [],
    }
  },
  created() {
  },
  mounted() {
    this.uploadList = this.$refs.upload.fileList;
  },
  watch: {
    uploadList(list) {
      if (list.length > 0) {
        let obj = undefined
        if (this.imgNumber > 1) {
          obj = list.map((item) => {
            return item.url
          })
        } else {
          obj = list[0]
        }
        this.$emit('ok', obj)
      }
    }
  },
  props: {
    // 预览框大小
    width: {
      type: Number,
      default: () => {
        return 100
      }
    },
    // 自动上传
    autoUpload: {
      type: Boolean,
      default: () => {
        return true
      }
    },
    // 图片数量
    imgNumber: {
      type: Number,
      default: () => {
        return 9
      }
    }
  },
  methods: {
    handleView(name) {
      this.imgName = name;
      this.visible = true;
    },
    handleRemove(file) {
      const fileList = this.uploadList;
      this.uploadList.splice(fileList.indexOf(file), 1);
    },
    beforeUpload(file){
      console.log(file);
      let file_path = ''
      if(!this.autoUpload){
        file_path = URL.createObjectURL(file)
        this.uploadList.push({
          status: 'finished',
          url: file_path,
          file: file
        })
        // this.$emit('ok', file)
      }
      return this.autoUpload
    },
    handleSuccess(res, file) {
      if (res.code == 200) {
        file.url = res.msg
        file.name = res.data.name
      }
    },
    handleFormatError(file) {
      this.$Notice.warning({
        title: 'The file format is incorrect',
        desc: 'File format of ' + file.name + ' is incorrect, please select jpg or png.'
      });
    },
    handleMaxSize(file) {
      this.$Notice.warning({
        title: 'Exceeding file size limit',
        desc: 'File  ' + file.name + ' is too large, no more than 2M.'
      });
    }
  }
}
</script>

<style scoped lang="less">
.demo-upload-list {
  display: inline-block;
  text-align: center;
  border-radius: 4px;
  overflow: hidden;
  background: #fff;
  position: relative;
  box-shadow: 0 1px 1px rgba(0, 0, 0, .2);
  border: 1px dashed #dcdee2;
  margin-right: 4px;
}

.demo-upload-list img {
  width: 100%;
  height: 100%;
}

.demo-upload-list-cover {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, .6);
}

.demo-upload-list:hover .demo-upload-list-cover {
  display: block;
}

.demo-upload-list-cover i {
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  margin: 0 2px;
}
</style>
