import common from "./common";
import teacher from "./teacher";
import student from "./student";
import commonclass from "./commonclass";

export const api = {
    ...common,
    ...teacher,
    ...student,
    ...commonclass
}
