const TOKEN = 'token'
const OPEN_ID = 'open_id'
const USER_INFO = 'user_info'

export default {
    setToken(val) {
        sessionStorage.setItem(TOKEN, val)
    },
    getToken() {
        return sessionStorage.getItem(TOKEN) || undefined
    },
    setOpenId(val) {
        sessionStorage.setItem(OPEN_ID, val)
    },
    getOpenId() {
        return sessionStorage.getItem(OPEN_ID) || undefined
    },
    getUserInfo() {
        return jsonParse(sessionStorage.getItem(USER_INFO) || false)
    },
    clear() {
        localStorage.clear()
        sessionStorage.clear()
    },
    setRole(val){
        sessionStorage.setItem('role',val);
    },
    getRole(){
        return sessionStorage.getItem('role') || undefined
    }

}

function jsonParse(data) {
    return JSON.parse(data)
}
