import Main from '../views/main/index';

// 公共路由
const common = [
  {
    path: '/login',
    name: 'login',
    meta: {
      title: '登陆',
      auth: 0
    },
    component: () => import('../views/login/index.vue')
  },
  {
    path: '/',
    name: 'index',
    meta: {
      title: '首页',
      auth: 0
    },
    component: Main,
    children: [
      {
        path: '/',
        name: 'home',
        meta: {
          title: '首页',
          auth: 0
        },
        component: () => import('../views/home/index.vue')
      },{
        path: '/common-homework',
        name: 'common-homework',
        meta: {
          title: '首页',
          auth: 0
        },
        component: () => import('../views/modules/common/homeWork/list.vue')
      },
    ]
  }
]

export default common
