import Vue from 'vue';
import VueRouter from 'vue-router';
import {routers} from './router';
//import store from '../store'

import user from '../user';

Vue.use(VueRouter);

// 路由配置
const RouterConfig = {
  mode: 'history',
  routes: routers
};

export const router = new VueRouter(RouterConfig);

router.beforeEach((to, from, next) => {
  let isLogin = user.getToken();
  if (!isLogin && to.name !== 'login') { // 判断是否已经登录且前往的页面不是登录页
    next('/login');
  } else if (isLogin && to.name === 'login') {
    next('/')
  } else {
    // 路由鉴权
    // let auth = to.meta.auth
    // if (auth > 0) {
    //   let user = store.state.user.info
    //   if (user) {
    //     let identity = user.identity
    //     // 最高权限小于路由权限，404
    //     if (identity < auth) {
    //       next('/404')
    //     }
    //   }
    // }
    next();
  }
});

router.afterEach(() => {
  window.scrollTo(0, 0)
})
