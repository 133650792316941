<template>
  <div class="layout-main">
    <div class="layout-main-left" :class="{'show':showNav}" :style="{minWidth: showNav ? '' : '0'} ">
      <div class="layout-main-left-user" v-if="isIndex">
        <div class="user-info-avatar">
          <img :src="avatar"/>
        </div>
        <!--        <div class="user-info-school">嘉善实验小学</div>-->
        <div class="user-info-nickname">{{ userInfo.name }}</div>
        <div class="user-info-outlogin">
          <Button type="primary" @click="outLogin">退出登录</Button>
        </div>
      </div>
      <div class="layout-main-logo" v-else>
        <router-link to="/" tag="div" class="layout-main-logo-item">
          <div class="layout-main-logo-item-icon"></div>
        </router-link>
      </div>
      <div class="layout-main-nav" :class="{'shadow':!isIndex}">
        <div class="layout-main-nav-item-box" :class="{'active':item.path == $route.path}"
             v-for="(item,index) in navList" :key="index">
          <router-link :to="item.path" class="layout-main-nav-item" tag="div">
            <div class="nav-item-icon">
              <Icon size="22" :type="item.icon"/>
            </div>
            <div class="nav-item-name" v-text="item.name"></div>
          </router-link>
        </div>
      </div>
    </div>
    <div class="layout-main-right">
      <div class="layout-main-right-header" v-if="!isIndex">
        <div class="header-menu">
          <div class="header-menu-icon" v-if="!showNav" @click="showNavDrawer = true">
            <Icon type="md-menu" size="30" color="#3e94ff"/>
          </div>
        </div>
        <div class="header-user">
          <div class="user-info">
            <div class="user-avatar">
              <img :src="avatar"/>
            </div>
            <div class="user-name">{{ userInfo.name || '管理员' }}</div>
          </div>
          <div class="login-out">
            <div class="login-out-item" @click="outLogin">
              <div class="login-out-icon"></div>
              <div class="login-out-name">退出</div>
            </div>
          </div>
        </div>
      </div>
      <div class="page-content" :class="{'top':!isIndex}">
        <router-view/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "layout-main",
  components: {},
  data() {
    return {
      showNav: true,
      isIndex: true,
      navList: [],
      tempMenu: [
        // {
        //   auth: 10,
        //   name: '发布作业',
        //   path: '/pushwork',
        //   icon: 'md-send'
        // },
        {
          auth: 10,
          name: '班级管理',
          path: '/teacher-commonclass',
          icon: 'md-albums'
        },{
          auth: 20,
          name: '班级管理',
          path: '/school-commonclass',
          icon: 'md-barcode'
        },
        {
          auth: 20,
          name: '教师管理',
          path: '/school-teacher',
          icon: 'md-people'
        },
        {
          auth: 10,
          name: '测评管理',
          path: '/common-homework',
          icon: 'md-barcode'
        },
        {
          auth: 10,
          name: '批量扫描',
          path: '/batch-scan',
          icon: 'md-aperture'
        },
      ],
      userInfo: this.$user.getUserInfo(),
      role: '',
      avatar: this.$user.getUserInfo().avatar || require("../../assets/images/home/headpic.png")
    }
  },
  created() {
    let route = this.$route
    if (route.path != '/') {
      this.isIndex = false
    }
    this.onreScreenWidth();
  },
  mounted() {
    const that = this
    window.onresize = () => {
      return (() => {
        that.onreScreenWidth()
      })()
    };
    let tempMenu = this.tempMenu;
    let role = this.$user.getRole();
    let navList = []
    switch (role) {
      case 'teacher':
        tempMenu.forEach((item) => {
          if (item.auth == 10) {
            navList.push(item)
          }
        })
        break;
      case 'school':
        tempMenu.forEach((item) => {
          if (item.auth > 10) {
            navList.push(item)
          }
        })
        break;
    }
    this.navList = navList
  },
  watch: {
    '$route': {
      handler(val) {
        if (val.path == '/') {
          this.isIndex = true
        } else {
          this.isIndex = false
        }
      },
      deep: true
    }
  },
  methods: {
    onreScreenWidth() {
      let screenWidth = document.documentElement.clientWidth
      if (screenWidth > 800) {
        this.showNav = true
      } else {
        this.showNav = false
      }
    },
    outLogin() {
      this.$Modal.confirm({
        title: '提示',
        content: '确认退出本次登录吗？',
        okText: '退出',
        onOk: () => {
          this.$user.clear();
          window.location.href = '/login'
          // this.$http.logout().finally(() => {
          //   sessionStorage.clear()
          //   this.$user.clear();
          //   this.$router.push({
          //     path: '/login'
          //   })
          // });
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
.page-content {
  margin: 10px;

  &.top {
    margin-top: 90px;
  }
}

.layout-main {
  display: flex;
  width: 100vw;
  height: 100vh;
  min-height: 650px;
  background-color: #f1f7fb;

  .layout-main-left {
    display: flex;
    flex-direction: column;
    width: 0;
    min-width: 260px;
    opacity: 0;
    min-height: 650px;
    background-color: white;
    z-index: 11;
    transition: all 3ms ease;

    &.show {
      width: 260px;
      opacity: 1;
    }

    &.hide {
      width: 0;
      min-width: 0;
      opacity: 0;
    }

    .layout-main-left-user {
      margin-top: 55px;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      .user-info-avatar {
        width: 150px;
        height: 150px;
        padding: 1px;
        border: 3px solid #91beff;
        border-radius: 100%;
        overflow: hidden;
        margin-bottom: 30px;
      }

      .user-info-school {
        color: #444444;
        font-size: 20px;
      }

      .user-info-nickname {
        font-size: 22px;
        font-weight: bold;
        color: #333333;
      }

      .user-info-outlogin {
        margin-top: 30px;
      }

    }

    .layout-main-logo {
      height: auto;
      transition: height 0.2ms;

      .layout-main-logo-item {
        height: 70px;
        cursor: pointer;
        background: #1679f4;
        background: -webkit-linear-gradient(to right, #1679f4, #3892fc);
        background: linear-gradient(to right, #1679f4, #3892fc);

        .layout-main-logo-item-icon {
          width: 100%;
          height: 100%;
          background-position: center center;
          background-image: url("../../assets/images/home/title_4.png");
          background-repeat: no-repeat;
          background-size: 50%;
          //display: flex;
          //justify-content: center;
          //align-items: center;
          //font-size: 40px;
          //letter-spacing: 20px;
          //color: white;
        }
      }
    }

    .layout-main-nav {
      padding-top: 50px;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      height: 100vh;
      overflow: hidden;

      &.shadow {
        box-shadow: 10px 0 10px -5px #ecf3f8;
      }

      .layout-main-nav-item-box {
        width: 75%;
        height: 60px;
        line-height: 60px;
        padding: 0 10px;
        margin-bottom: 30px;
        cursor: pointer;
        border-radius: 60px;

        &.active {
          .nav-item-icon i {
            color: #FFFFFF;
          }

          &:hover {
            background-color: #3e94ff;
          }

          background-color: #3e94ff;
          box-shadow: 0 4px 9px #b1d4ff;

          .layout-main-nav-item {
            .nav-item-name {
              color: #FFFFFF;
            }
          }
        }

        &:hover {
          background-color: #fcfcfc;
          box-shadow: 0 4px 9px #b1d4ff;
        }

        .layout-main-nav-item {
          display: flex;
          height: 60px;
          margin-left: 30px;

          .nav-item-name {
            margin-left: 10px;
            font-size: 20px;
            color: #A7A7C6;
          }

          .nav-item-icon {
            width: 25px;
            height: 25px;
            background-repeat: no-repeat;
            background-size: 100% 100%;
            color: #A3A7C6;

            &.img-1 {
              background-image: url("../../assets/images/home/icon_1.png");
            }

            &.img-2 {
              background-image: url("../../assets/images/home/icon_2.png");
            }

            &.img-3 {
              background-image: url("../../assets/images/home/icon_3.png");
            }

            &.img-5 {
              background-image: url("../../assets/images/home/icon_4.png");
            }

            &.img-4 {
              background-image: url("../../assets/images/home/icon_5.png");
            }
          }
        }
      }
    }

    .body-left-home {
      width: 100%;
      height: 80px;
      background-image: linear-gradient(to right, #1679f4, #28a1ff);
      cursor: pointer;

      .body-left-home-img {
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center center;
        background-image: url("../../assets/images/home/title_2.png");
      }
    }

    .body-left-down {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 86.5px;

      .body-left-down-item {
        display: flex;
        align-items: center;
        width: 200px;
        height: 52px;
        border-radius: 30px;
        margin-bottom: 58px;
        cursor: pointer;

        &.active {
          background-color: #3e94ff;
          box-shadow: 0px 4px 9px #b1d4ff;

          & > .body-left-down-name {
            color: white;
          }

          & > .img-1 {
            background-image: url("../../assets/images/home/icon_1_hover.png");
          }

          & > .img-2 {
            background-image: url("../../assets/images/home/icon_2_hover.png");
          }

          & > .img-3 {
            background-image: url("../../assets/images/home/icon_3_hover.png");
          }

          & > .img-4 {
            background-image: url("../../assets/images/home/icon_4_hover.png");
          }
        }

        .body-left-down-icon {
          margin-left: 28px;
          width: 32px;
          height: 32px;
          background-repeat: no-repeat;
          background-size: 100% 100%;
        }

        .body-left-down-name {
          margin-left: 28px;
          font-size: 22px;
          color: #a7a7c6;
        }

        .img-1 {
          background-image: url("../../assets/images/home/icon_1.png");
        }

        .img-2 {
          background-image: url("../../assets/images/home/icon_2.png");
        }

        .img-3 {
          background-image: url("../../assets/images/home/icon_3.png");
        }

        .img-4 {
          background-image: url("../../assets/images/home/icon_4.png");
        }

        &:hover {
          background-color: #3e94ff;
          box-shadow: 0px 4px 9px #b1d4ff;

          & > .body-left-down-name {
            color: white;
          }
        }

        &:hover > .img-1 {
          background-image: url("../../assets/images/home/icon_1_hover.png");
        }

        &:hover > .img-2 {
          background-image: url("../../assets/images/home/icon_2_hover.png");
        }

        &:hover > .img-3 {
          background-image: url("../../assets/images/home/icon_3_hover.png");
        }

        &:hover > .img-4 {
          background-image: url("../../assets/images/home/icon_4_hover.png");
        }
      }


    }
  }

  .layout-main-right {
    width: 100%;
    overflow: auto;

    .layout-main-right-header {
      position: fixed;
      left: 0;
      right: 0;
      z-index: 10;
      height: 70px;
      background-color: white;
      box-shadow: 0 10px 10px -5px #ecf3f8;
      //box-shadow: 0 10px 10px -5px #face99;
      display: flex;
      justify-content: space-between;
      overflow: hidden;

      .header-menu {
        .header-menu-icon {
          margin-top: 12px;
          margin-left: 20px;
          cursor: pointer;
        }
      }

      .header-user {
        display: flex;
        height: 46px;
        margin: 12px 0;

        .user-info {
          display: flex;
          cursor: pointer;
          margin-right: 20px;

          .user-avatar {
            width: 40px;
            height: 40px;
            margin: 5px 0;
            border: 1px solid #d1e8fa;
            border-radius: 40px;
            overflow: hidden;
          }

          .user-name {
            margin-left: 10px;
            line-height: 50px;
          }
        }

        .login-out {
          width: 120px;
          padding: 5px;
          margin-top: 5px;
          position: relative;

          &:before {
            position: absolute;
            content: '';
            width: 2px;
            margin-top: 3px;
            height: 30px;
            border-left: 2px solid #efefef;
          }

          .login-out-item {
            margin-left: 10px;
            display: flex;
            width: 90px;
            height: 33px;
            line-height: 33px;
            border-radius: 33px;
            padding: 0 10px;
            border: 1px solid #FFFFFF;
            cursor: pointer;

            &:hover {
              background-color: #fafafa;
              border: 1px solid #eeeeee;
            }

            .login-out-icon {
              width: 18px;
              height: 18px;
              margin-top: 6px;
              background-image: url("../../assets/images/main/icon_7.png");
              background-repeat: no-repeat;
              background-size: 100%;
            }

            .login-out-name {
              margin-left: 8px;
            }
          }
        }
      }
    }
  }
}
</style>
