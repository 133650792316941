<template>
 <div class="layout-breadcrumb">
   <Button style="margin-right: 10px;" v-if="list.length > 0" @click="goBack">
     <Icon type="ios-arrow-back" size="16"/>
     返回
   </Button>
   <Breadcrumb style="margin-top: 5px;">
     <BreadcrumbItem to="/">
       <Icon type="md-home" size="18"></Icon> 首页
     </BreadcrumbItem >
     <span v-for="(item,index) in list" :key="index">
       <BreadcrumbItem v-if="index >= (list.length - 1)">{{item.name}}</BreadcrumbItem>
       <BreadcrumbItem v-else>
         <Icon type="logo-buffer"></Icon> {{item.name}}
       </BreadcrumbItem>
     </span>
   </Breadcrumb>
 </div>
</template>

<script>
export default {
  name: "layout-breadcrumb",
  components: {},
  data() {
    return {}
  },
  created() {
  },
  mounted() {
  },
  props: {
    list: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  methods: {
    goHome() {
      this.$jump('/');
    },
    goBack() {
      this.$router.go(-1);
    }
  }
}
</script>

<style scoped lang="less">
.layout-breadcrumb{
  margin-bottom: 15px;
  display: flex;
}
.nav-hand {
  cursor: pointer;
}
</style>
