import {postAction, getAction, deleteAction} from './index';

// 公共接口
const teacher = {
    queryTeacherList: (pageNo, pageSize, username, nickname) => {
        return getAction(`school/queryTeacherList`, {pageNo, pageSize, username, nickname});
    },
    editTeacherInfo: (teacherId, nickName, password, classId) => {
        return postAction(`school/editTeacherInfo`, {teacherId, nickName, password, classId});
    },
    createTeacher: (userName, password, nickName, classId) => {
        return postAction(`school/createTeacher`, {userName, password, nickName, classId});
    },
    deleteTeacher: (teacherId) => {
        return deleteAction(`school/deleteTeacher`, {teacherId});
    },
    querySchoolTeacherList: (pageNo, pageSize, username, nickname) => {
        return postAction(`school/querySchoolTeacherList`, {pageNo, pageSize, username, nickname});
    },
    queryClassList: (enterYear) => {
        return getAction(`school/queryClassList`, {enterYear});
    },
    // 获取设备列表
    queryUserDevice: () => {
        return getAction(`mini/aiDevice/queryUserDevice`);
    },
    // 获取设备状态
    queryDeviceStatus: (deviceId) => {
        return getAction(`mini/aiDevice/queryDeviceStatus`, {deviceId: deviceId});
    },
    // 执行扫描
    deviceCommand: (type, deviceId) => {
        return getAction(`mini/aiDevice/command`, {type: type, deviceId: deviceId});
    },
    //查询当前教师的班级及其学生信息
    queryClassStudent: () => {
        return getAction(`teacher/queryClassStudent`);
    },
    //教师作业列表
    queryHomeworkList: (pageNo, pageSize, classId, startTime, endTime) => {
        return getAction(`teacher/queryHomeworkList`, {pageNo, pageSize, classId, startTime, endTime});
    },
    //某次作业提交状况
    queryHomeworkInfo: (pageNo, pageSize, homeworkNumber) => {
        return getAction(`teacher/queryHomeworkInfo`, {pageNo, pageSize, homeworkNumber});
    },
    //某个学生某次作业提交详情
    queryWordRecognitionById: (sysNo) => {
        return getAction(`mini/evaluation/queryWordRecognitionById`, {sysNo});
    },
    //基本信息--总识别次数
    countEvaluationTimes: () => {
        return getAction(`teacher/countEvaluationTimes`);
    },
    //测评报告
    queryEvaluationReport: (startTime, endTime) => {
        return getAction(`teacher/queryEvaluationReport`, {startTime, endTime});
    },
    editTeacherCommonClassInfo: (id, name, enterYear, number) => {
        return postAction(`teacher/editCommonClassInfo`, {id, name, enterYear, number});
    },
    // 创建班级
    createTeacherCommonClass: (name, enterYear, number) => {
        return postAction(`teacher/createCommonClass`, {name, enterYear, number});
    },
    //发布在线布置作业
    pushHomeOnline: (data) => {
        return postAction(`teacher/pushHomeOnline`, data);
    },
    //查询在线布置作业
    queryHomeworkCycle: (pageNo, pageSize, name, startTime, endTime) => {
        return getAction(`teacher/queryHomeworkCycle`, {pageNo, pageSize, name, startTime, endTime});
    },
    // 图片识别
    scanTeacherResult: (info) => {
        const data = new FormData();
        data.append("homeworkNumber", info.homeworkNumber);
        data.append("studentId", info.studentId);
        if(info.file.raw){
            data.append("file", info.file.raw);
        }else{
            data.append("file", info.file);
        }
        return postAction(`teacher/scanResultOne`, data);
    },
}

export default teacher
