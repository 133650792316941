<template>
  <div class="footer-box">
    <div class="note">
      <div class="note-1">Copyright © 2017-2021 浙江易学汇教育科技有限公司.</div>
      <div class="note-2">
        <a href="https://beian.miit.gov.cn/">浙ICP备19018832号-1</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "layout-footer",
  components: {},
  data() {
    return {}
  },
  created() {
  },
  mounted() {},
  methods: {}
}
</script>

<style scoped>

</style>
