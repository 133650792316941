import {postAction, getAction} from './index';

// 公共接口
const common = {
    login: (uid, pwd) => {
        return postAction(`pc/account/schoolLogin`, {uid, pwd});
    },
    // 退出登录
    outLogin: () => {
        return getAction(`pc/account/logout`);
    },
    // 上传
    upload: (file, type = '0') => {
        const param = new FormData();
        param.append("type", type);
        if (file.raw) {
            param.append("file", file.raw);
        } else {
            param.append("file", file);
        }
        return postAction(`v1/common/uploadCreate`, param);
    },
    // 刷新token
    flushToken: () => {
        return getAction(`v1/common/flushToken`);
    },
    // 获取用户信息
    userInfo: () => {
        return getAction(`v1/common/getUserDetail`);
    },
}

export default common
