import common from './common'
import school from './school'
import teacher from './teacher'

export const page404 = {
    path: '*',
    name: '404',
    meta: {
        title: '404-页面不存在',
        auth: 0
    },
    component: () => import('../components/404.vue')
};
export const page403 = {
    path: '/403',
    name: '403',
    meta: {
        title: '403-资源不可用',
        auth: 0
    },
    component: () => import('../components/403.vue')
};

export const routers = [
    ...school,
    ...common,
    ...teacher,
    page403,
    page404
]
