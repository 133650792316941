// 校管理路由
import Main from "@/views/main/index";

const school = [
  {
    path: '/school',
    name: 'school',
    meta: {
      title: '学校',
      auth: 0
    },
    component: Main,
    children: [
      {
        path: '/',
        name: 'school-index',
        meta: {
          title: '校管理首页',
          auth: 30
        },
        component: () => import('../views/modules/school/analyze/index.vue')
      },
      {
        path: '/school-commonclass',
        name: 'school-commonclass',
        meta: {
          title: '班级管理',
          auth: 30
        },
        component: () => import('../views/modules/school/school-commonclass.vue')
      },{
        path: '/school-teacher',
        name: 'school-teacher',
        meta: {
          title: '教师管理',
          auth: 30
        },
        component: () => import('../views/modules/school/school-teacher.vue')
      },
      // {
      //   path: '/school/manager',
      //   name: 'school-manager',
      //   meta: {
      //     title: '教务中心',
      //     auth: 30
      //   },
      //   component: () => import('../views/modules/school/manager/teacher-use.vue')
      // }
    ]
  }
]

export default school
