import {postAction, getAction, deleteAction} from './index';

// 公共接口
const student = {
    queryStudentList: (pageNo, pageSize, username, nickname, classId, enterYear) => {
        return getAction(`teacher/queryStudentList`, {pageNo, pageSize, username, nickname, classId, enterYear});
    },
    editStudentInfo: (studentId, nickName, studentNo, password) => {
        return postAction(`teacher/editStudentInfo`, {studentId, nickName, studentNo, password});
    },
    createStudent: (file, classId) => {
        const param = new FormData();
        param.append("file", new Blob([file]));
        param.append("classId", classId);
        return postAction(`teacher/createStudent`, param);
    },
    createOneStudent: (nickName, classId, studentNo) => {
        return postAction(`teacher/createOneStudent`, {nickName, classId, studentNo});
    },
    deleteStudent: (studentId) => {
        return deleteAction(`teacher/deleteStudent`, {studentId});
    },
    queryTeacherClassList: () => {
        return getAction(`teacher/queryTeacherClassList`);
    }
}

export default student
