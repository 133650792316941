import {postAction, getAction, deleteAction} from './index';

// 公共接口
const commonclass = {
    uploadFile:(file) =>{
        const data = new FormData();
        if(file.raw){
            data.append("file", file.raw);
        }else{
            data.append("file", file);
        }
        return postAction(`api/qiniu/file/upload`, data);
    },
    querySchoolCommonClassList: (pageNo, pageSize, enterYear, commonClassName) => {
        return getAction(`school/queryCommonClassList`, {
            pageNo,
            pageSize,
            enterYear,
            commonClassName
        });
    },
    queryTeacherCommonClassList: (pageNo, pageSize, enterYear, commonClassName) => {
        return getAction(`teacher/queryCommonClassList`, {
            pageNo,
            pageSize,
            enterYear,
            commonClassName
        });
    },
    deleteCommonClass: (classId) => {
        return deleteAction(`school/deleteCommonClass`, {classId});
    },
}

export default commonclass
