<template>
  <div>layout-header</div>
</template>

<script>
export default {
  name: "layout-header",
  components: {},
  data() {
    return {}
  },
  created() {
  },
  mounted() {
  },
  methods: {}
}
</script>

<style scoped>

</style>
